<template>
  <div :class="$style.container">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList"></Breadcrumb>
    <a-form
      ref="form"
      class="al-form"
      layout="inline"
      :model="form"
      :label-col="{ style: 'width: 80px' }"
      :wrapper-col="{ style: 'width: 170px' }">
      <a-form-item>
        <a-input v-model="form.nameContent" placeholder="商品名称" />
      </a-form-item>
      <a-form-item>
        <a-select v-model="form.status" placeholder="状态">
          <a-select-option :value=0>不显示</a-select-option>
          <a-select-option :value=1>已显示</a-select-option>
          <a-select-option :value=2>内容违规</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="isQueryLoading" @click="queryList">查询</a-button>
        <a-button @click="resetForm" style="margin-left:20px;">重置</a-button>
      </a-form-item>
    </a-form>
    <div>
      <a-tabs default-active-key="" @change="callback1" :activeKey="activeKey">
        <a-tab-pane v-for="item in evaluateStatusTags" :key="item.tagCode" :tab="item.tagName"/>
      </a-tabs>
    </div>
    <div>
       <a-button type="primary"  @click="configEdit">设置评论审核</a-button>
    </div>
    <br/>
    <a-table
      :data-source="list"
      :rowKey="record => record.id"
      :columns="columns"
      :pagination="pagination"
      :scroll="{ x: 1750, y: 580 }">
      <span slot="action1" slot-scope="scope">
        <div style="color: #4f9efa;">{{scope.usUserId}}</div>
        <div style="color: #4f9efa;">{{scope.usUserName}}</div>
      </span>
      <span slot="action2" slot-scope="scope">
        <div>{{scope.idContent}}</div>
        <div>{{scope.nameContent}}</div>
      </span>
      <span slot="action3" slot-scope="scope">
       <div>{{scope.score}}</div>
      </span>
      <span slot="action4" slot-scope="scope">
        <div v-if="scope.status === 0" style="color: #4f9efa;">隐藏</div>
        <div v-if="scope.status === 1" style="color: #4f9efa;">隐藏</div>
        <div v-if="scope.status === 2" style="color: red;">内容违规</div>
      </span>
      <span slot="action5" slot-scope="scope">
        <div v-if="scope.status === 0" style="color: #4f9efa;">隐藏</div>
        <div v-if="scope.status === 1" style="color: #4f9efa;">隐藏</div>
        <div v-if="scope.status === 2" style="color: red;">内容违规</div>
        <div>{{scope.content}} </div>
      </span>
      <span slot="action6" slot-scope="scope">
        <span style="margin-left:2px;float: left;">
          <span v-for="(evaluateImag,index) in scope.imageList" :key="index">
            <a  @click="zoom(evaluateImag)">
              <a-avatar shape="square"
                style="width:80px;height:80px;margin-left:5px;margin-top:2px;"
                :src="evaluateImag" />
            </a>
          </span>
        </span>
      </span>
      <span slot="action" slot-scope="scope">
        <a href="#" v-if="scope.choiceness === 0" @click="choiceness(scope, 1)"><a-spin size="small" v-if="scope.isLoading" />精选</a>
        <a href="#" v-if="scope.choiceness === 1" @click="choiceness(scope, 0)"><a-spin size="small" v-if="scope.isLoading" />非精选</a>
        <a-divider type="vertical" />
        <span  v-if="scope.status === 0">
          <a href="#"  @click="auditStatus(scope, 1)"><a-spin size="small" v-if="scope.isLoading" />显示</a>
          <a-divider type="vertical" />
          <a href="#" @click="auditStatus(scope, 2)"><a-spin size="small" v-if="scope.isLoading" />违规内容</a>
          <a-divider type="vertical" />
        </span>
        <span  v-if="scope.status === 1">
          <a href="#" @click="auditStatus(scope, 0)"><a-spin size="small" v-if="scope.isLoading" />隐藏</a>
          <a-divider type="vertical" />
          <a href="#" @click="auditStatus(scope, 2)"><a-spin size="small" v-if="scope.isLoading" />违规内容</a>
          <a-divider type="vertical" />
        </span>
        <span  v-if="scope.status === 2">
          <a href="#" @click="auditStatus(scope, 0)"><a-spin size="small" v-if="scope.isLoading" />隐藏</a>
          <a-divider type="vertical" />
          <a href="#" @click="auditStatus(scope, 1)"><a-spin size="small" v-if="scope.isLoading" />显示</a>
          <a-divider type="vertical" />
        </span>
        <a-popconfirm
            title="你确定要删除这行内容吗?"
            ok-text="是"
            cancel-text="否"
            @confirm="confirm(scope)"
            @cancel="cancel"
          >
            <a href="#"><a-spin size="small" v-if="scope.isLoading" />删除</a>
          </a-popconfirm>
      </span>
    </a-table>
    <br/>
    <a-modal
      :footer="null"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <img style="width:100%" :src="evaluateImag"/>
    </a-modal>
     <!-- 编辑 优惠券设置 弹窗 -->
    <a-modal
      :title="configEditModalTitle"
      :footer="null"
      :visible="configEditVisible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
        <ConfigEdit  @ok="handleOk" @cancel="handleCancel" ref="configEditModalRef"></ConfigEdit>
    </a-modal>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import ConfigEdit from '../configEdit.vue'

export default {
  components: {
    Breadcrumb,
    ConfigEdit
  },
  data () {
    return {
      currentTenantId: this.$store.state.user.currentTenantId,
      breadList: [
        {
          name: '评价管理',
          url: ''
        }
      ],
      visible: false,
      evaluateImag: '',
      configEditModalTitle: '评价审核设置',
      configEditVisible: false, // 评价审核设置弹窗
      isQueryLoading: false,
      form: {
        nameContent: '',
        status: undefined,
        searchKey: ''
      },
      activeKey: '',
      evaluateStatusTags: [
        {
          tagCode: '',
          tagName: '全部'
        },
        {
          tagName: '用户自评',
          tagCode: 'reality'
        },
        {
          tagName: '带图评价',
          tagCode: 'includeImg'
        },
        {
          tagName: '精选评价',
          tagCode: 'choiceness'
        },
        {
          tagName: '差评',
          tagCode: 'bad'
        }
      ],
      columns: [
        { title: '评价人', key: '', width: 200, scopedSlots: { customRender: 'action1' } },
        { title: '评价商品', key: '', width: 350, scopedSlots: { customRender: 'action2' } },
        { title: '评分', width: 80, key: 'showStatus', scopedSlots: { customRender: 'action3' } },
        { title: '评级状态', key: '', width: 120, scopedSlots: { customRender: 'action4' } },
        { title: '评价内容', key: '', width: 200, scopedSlots: { customRender: 'action5' } },
        { title: '晒图', key: '', width: 380, scopedSlots: { customRender: 'action6' } },
        { title: '评价时间', dataIndex: 'gmtCreate', key: 'gmtCreate', width: 180 },
        { title: '操作', scopedSlots: { customRender: 'action' }, key: 'operation', fixed: 'right', width: 240 }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共 ${total} 条记录 第 ${range[0]}-${range[1]} 条数据`,
        onChange: pageNo => this.pageNoChange(pageNo),
        onShowSizeChange: (pageNo, pageSize) => this.pageSizeChange(pageNo, pageSize)
      },
      total: 0,
      list: []
    }
  },
  created () {
    this.reloadList()
  },
  methods: {
    // 重置表单
    resetForm () {
      this.form = {
        nameContent: '',
        status: undefined,
        searchKey: ''
      }
      this.queryList()
    },
    // 评价审核设置
    configEdit () {
      this.configEditModalTitle = '评价审核设置'
      this.configEditVisible = true
      this.$nextTick(() => {
        this.$refs.configEditModalRef.init()
      })
    },
    handleOk (e) {
      setTimeout(() => {
        this.visible = false
        this.configEditVisible = false
      })
    },
    handleCancel (e) {
      this.visible = false
      this.configEditVisible = false
    },
    zoom (scope) {
      this.visible = true
      this.evaluateImag = scope
    },
    callback1 (key) {
      this.form.searchKey = key
      this.activeKey = key
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.pagination.current = 1
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => {}) {
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => {}) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        evaluateType: 1
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'evaluatePage',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      records.forEach(item => {
        item.isLoading = false
      })
      this.list = records
    },
    // 页码切换
    pageNoChange (pageNo) {
      this.pagination.current = pageNo
      this.loadList()
    },
    // 每页显示条数切换
    pageSizeChange (pageNo, pageSize) {
      this.pagination.pageSize = pageSize
      this.pagination.current = pageNo
      this.reloadList()
    },
    cancel () {},
    // 删除评论
    confirm (scope) {
      this.del(scope)
    },
    async del (scope) {
      scope.isLoading = true
      const res = await this.$store.dispatch('http', {
        api: 'evaluateDelete',
        query: {
          id: scope.id
        }
      })
      if (res) {
        scope.isLoading = false
        this.$store.dispatch('showToast', { message: '删除成功~' })
        this.queryList()
      }
    },
    // 设置显示状态操作
    auditStatus (scope, status) {
      let msg = '确定设置为隐藏吗？'
      if (status === 1) {
        msg = '确定设置为显示吗？'
      }
      if (status === 2) {
        msg = '确定设置为违规内容吗？'
      }
      this.$store.dispatch('showConfirm', {
        message: msg,
        yes: async _ => {
          scope.isLoading = true
          await this.$store.dispatch('http', {
            api: 'updateEvaluateStatus',
            query: {
              id: scope.id,
              status: status
            },
            complete: () => {
              scope.isLoading = false
              this.$store.dispatch('showToast', { message: '操作成功~' })
              this.reloadList()
            }
          })
        }
      })
    },
    // 设置精选操作
    choiceness (scope, status) {
      let msg = '确定设置为精选评论吗？'
      if (status === 0) {
        msg = '确定设置为非精选评论吗？'
      }
      this.$store.dispatch('showConfirm', {
        message: msg,
        yes: async _ => {
          scope.isLoading = true
          await this.$store.dispatch('http', {
            api: 'updateChoiceness',
            query: {
              id: scope.id,
              status: status
            },
            complete: () => {
              scope.isLoading = false
              this.$store.dispatch('showToast', { message: '审核成功~' })
              this.reloadList()
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  .form__ct {
    padding: 10px 0;
  }
  .form__items {
    padding-top: 6px;
  }
}
</style>
